<script>
import {
    Bar
} from 'vue-chartjs';

export default {
    extends: Bar,
    name: 'Data',
    data() {
        return {
            data: {
                labels: ['0', '30分後', '3時間後', '6時間後'],
                datasets: [{
                        label: '光触媒除菌消臭ミスト',
                        data: ['6.5', '2.5', '2.5', '2.5'],
                        borderColor: 'green',
                        fill: false,
                        type: 'line',
                        lineTension: 0,
                    },
                    {
                        label: '対照群',
                        data: ['6.5', '6.3', '6.3', '6.3'],
                        borderColor: '#aaa',
                        fill: false,
                        type: 'line',
                        lineTension: 0,
                    },
                ]
            },
            options: {
                scales: {
                    xAxes: [{
                        ticks: {
                            display: true,
                            fontSize: '16',
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                            min: 2,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'ウイルス感染価(log TCID50/mL)',
                        },
                    }]
                },
                legend: {
                    display: true
                },
                title: {
                    display: true,
                    position: 'top',
                    fontColor: '#333',
                    fontSize: '16',
                    padding: '10',
                    text: ['ノロウイルス代替ウイルス（ネコカリシウイルス）'],
                }
            }
        }
    },
    props: {
        Notitle: Boolean,
        IsData: Boolean,
    },
    mounted() {
        if (this.Notitle) {
            this.options.title = null
            return false
        }
        this.renderChart(this.data, this.options)
    },
    watch: {
        IsData(val) {
            if (val) {
                this.renderChart(this.data, this.options)
            }
        }
    }
}
</script>
