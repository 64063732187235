<template>
<div class="text-center relative pt-16 section">
    <img src="@/assets/img/bg01.svg" class="absolute bg01">
    <img src="@/assets/img/bg01.svg" class="absolute bg01_02">
    <h1 class="f-gray pb-6 f-30 f-20-xs f-18-xxs">
        わかりやすい光触媒<br>
        <span class="f-16 f-14-xs">― 抗菌メカニズム ―</span>
    </h1>
    <div class="box01 relative-center">
        <v-row>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12">
                <div class="relative inline-block rounded inner ">
                    <div class="text-center inline-block  ">
                        <h2><span class="noto9">ど</span>ういう「しくみ」なの？</h2>
                        <div class="text-left inline-block f-12 f-11-sm f-10-xs bold mt-4 text_p ">
                            光触媒に光が当たると大量の活性酸素が生成されます<br>
                            活性酸素は、あらゆる有機物（細菌・ウイルス・ニオイ・シックハウスの原因成分等）と
                            瞬時に化合し、無害な物質（主に水と二酸化炭素）へと分解してしまいます<br>
                            一度分解された有機物は、二度と元には戻りません

                        </div>
                    </div>
                    <br>
                    <img src="@/assets/img/sec11_01.svg" class="sec11_01  mt-8" />
                </div>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12">
                <div class="relative inline-block rounded inner inner02">
                    <div class="text-center inline-block  ">
                        <h2>「<span class="noto9 f-orange">光</span> が当たる」 ということ</h2>
                        <div class="text-left inline-block f-12 bold mt-4 text_p ">
                            たとえば、物や景色を私たちが目で見ることができるのは
                            そこに光が当たっているからです<br>
                            「物が見えている状態」とは、まさに「光が当たっている状態」であり
                            目で見て活動できるエリアでは、光触媒もまた光を受けてフル稼働しています<br>
                            光が必要だからと、わざわざ強い光源を浴びせる必要はありません

                        </div>
                    </div>
                    <br>
                    <img src="@/assets/img/sec11_02.svg" class="sec11_02 mt-8" />
                </div>
            </v-col>
        </v-row>
        <br>
        <div class="relative inline-block rounded  inner">
            <div class="text-center inline-block  inner03">
                <h2><span class="noto9 ">成</span> 分が減らない、劣化しない</h2>
                <div class="text-left inline-block f-12 bold mt-4 text_p ">
                    光触媒は、大量に生成された活性酸素の働きによって有機物を瞬時に分解しますが
                    自らが直接作用するわけではない分、化学反応の前後で成分が減ることもなければ
                    品質が劣化することもなく、成分が残存する限り有機物の分解（除菌・消臭）を繰り返します<br>
                    電源を必要としないため、故障や誤作動が起きる心配がありません<br>
                    施工は1度きり 後は溶剤の補充もメンテナンスも不要で、人の手が一切かかりません<br>
                    光触媒の持つ、この『減らない』『劣化しない』という特性こそが
                    感染対策に欠かせない「長期持続性」「手間要らず」「低コスト」を導き出しているのです

                </div>
            </div>
            <br>
            <img src="@/assets/img/sec11_03.svg" class="sec11_01  mt-8" />
        </div>
    </div>
    <div class="py-16" />
</div>
</template>

<script>
export default {
    data() {
        return {
            comments: [
                "専用のガジェットを用いて光触媒を施工します<br>コンプレッサーを用いないため小さな作業音で手早く作業が完了します",
                "最先端の光触媒の働きによって、LEDなどの弱い室内光でも除菌消臭が繰り返し行われます",
                "暗い室内では、光触媒に付与されたアパタイトが菌やニオイを吸着。２４時間 休まずに除菌消臭します"
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/section11.scss";
</style>
