<template>
<div class="text-center bg pt-16">
    <h1 class="f-blue pb-6 f-12-xs">
        光触媒コーティング施工とは
    </h1>
    <div class="f-12 mb-10 f-10-xs px-2">
      施設内の壁全面または車内全面に光触媒の膜をコーティングし、そこから生成される活性酸素の働きによって<br class="disno-sm">
除菌＆消臭が繰り返し行われ、施設内または車内が常に清潔な状態に保たれます
    </div>
    <div class="inline-block">
        <div v-for="i in 3" :key="i" class="flow">
            <div class=" photo_box relative">
            <div class="number dis-1400 f-white bold absolute t-0 l-0 inkine-block z-1 f-12">
                {{i}}
            </div>
                <div class="flex-center">
                        <div class="photo relative">
                            <img :src="require('@/assets/img/sec02_0'+i+'.jpg')" />
                        <div class="f-11 absolute_x comments bold f-gray text-left inline-block" v-html="comments[i-1]" />
                        </div>
                    <div class="triangle disno1400" v-show="i!=3" />
                     <div class="clear" />
                </div>
            </div>
        </div>
        <div class="clear" />
    </div>
    <div class="bottom"/>
</div>
</template>

<script>
export default {
    data() {
        return {
            comments: [
            "専用の電動式ガジェットを用いてスピード施工いたします。「低音」そして「低価格」あっという間に仕上げます",
             "室内向けに改良された最先端光触媒によって、LEDや蛍光灯等のわずかな光にも効率良く反応します",
             "24時間フル稼働の昼夜連携タイプです。暗い室内では光触媒に代わってアパタイト成分が菌やニオイを強力に吸着します"
             ]
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/section02.scss";
</style>
