<template>
<div id="top" class="relative w-100">
    <slider animation="fade" :control-btn="false" :indicators="false" :interval="5000">
        <slider-item v-for="(i, index) in list" :key="index" :style="i">
            <div v-html="texts[index]" class="sub_text absolute f-20 f-13-sm f-10-xs serif fuchi z-101 text-left "></div>
        </slider-item>
    </slider>
    <div class="filter absolute t-0 l-0 z-100"></div>
    <h1 class="absolute z-100 noto7 inline-block text-center">
        <div class=" f-45 f-35-md f-25-xs f-20-xxs serif text inline-block text-left">
            光触媒が息吹く<span class="px-2 disno-sm"></span><br class="dis-sm">真の生活再生
        </div>
    </h1>
</div>
</template>

<script>
import {
    Slider,
    SliderItem
} from 'vue-easy-slider'

export default {
    components: {
        Slider,
        SliderItem,
    },
    data() {
        return {
            list: [{
                    backgroundImage: "url('../../img/slider/1.jpg')",
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                      backgroundPosition: 'center'
                },
                {
                    backgroundImage: "url('../../img/slider/2.jpg')",
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                      backgroundPosition: 'center'
                },
                {
                    backgroundImage: "url('../../img/slider/3.jpg')",
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                      backgroundPosition: 'center'
                },
                {
                    backgroundImage: "url('../../img/slider/4.jpg')",
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                      backgroundPosition: 'center'
                },
            ],
            texts: [
                "安寧のひとときを　<br class='dis-xs'>大切な人と過ごす　<br class='dis-xs'>癒しの空間",
                "爽快なオフィスが迎えてくれる　<br class='dis-xs'>新たな1日のはじまり",
                "清々しさに包まれ　<br class='dis-xs'>日々の活力が自ずと湧いてくる",
                "ともに集い　ともに笑う　<br class='dis-xs'>大好きな学び舎を再び"
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/slider.scss";
@import "@/assets/sass/btn.scss";
</style>
